<template>
  <div>
    <b-row>
      <b-col md="6">
        <div id="ulke-dropdown-container">
          <span>Ülke seçiniz: </span>
          <v-select
            v-model="selectedCountry"
            label="countryName"
            :options="ulkeList"
          />
          <br>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.ilceekle-modal
            class="d-flex align-items-left"
            squared
            variant="outline-primary"
            :disabled="selectedCity.cityId == -1"
          >
            <feather-icon
              icon="AddIcon"
              class="mr-50"
            />
            <span>İlçe Ekle</span>
          </b-button>
        </div>
      </b-col>
      <b-col md="6">
        <div id="ulke-dropdown-container">
          <span>Sehir seçiniz: </span>
          <v-select
            v-model="selectedCity"
            label="cityName"
            :options="sehirList"
          />
          <br>
        </div>
      </b-col>
    </b-row>
    <br>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        placeholder:'İlçe ara'}"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      theme="my-theme"
      @on-row-click="onDataGridItemClicked"
    >
      <div slot="emptystate">
        <center> İlçe kaydı bulunmuyor.</center>
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <template>
              <b-button-group
                class="mt-25"
                size="sm"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  class="mr-1"
                  squared
                  variant="outline-warning"
                  @click="onUpdateTownBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Düzenle</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  squared
                  variant="outline-danger"
                  @click="onDeleteTownBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Sil</span>
                </b-button>
              </b-button-group>
            </template>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- İlçe ekle modal -->
    <b-modal
      id="ilceekle-modal"
      cancel-variant="outline-secondary"
      ok-title="Ekle"
      cancel-title="İptal"
      centered
      no-close-on-backdrop
      title="İlçe Ekle"
      @ok="onModalSaveTown"
      @cancel="onModalCancelled"
    >
      <b-form>
        <b-form-group>
          <label for="townName">İlçe Adı:</label>
          <b-form-input
            id="townName"
            v-model="newTown.townName"
            type="text"
            placeholder="İlçe Adı"
            :state="newTown.townName.length > 0"
          />
          <b-form-invalid-feedback>
            İlçe adı boş bırakılamaz.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-modal>
    <!--  İlçe güncelle modal -->
    <b-modal
      id="ilceguncelle-modal"
      cancel-variant="outline-secondary"
      ok-title="Guncelle"
      cancel-title="İptal"
      centered
      no-close-on-backdrop
      title="İlçe Güncelle"
      @ok="onModalUpdateTown"
      @cancel="onModalCancelled"
    >
      <b-form>
        <b-form-group>
          <label for="townId">Identity</label>
          <b-form-input
            id="townId"
            v-model="newTown.townId"
            disabled
            type="text"
          />
        </b-form-group>
        <b-form-group>
          <label for="townName">İlçe:</label>
          <b-form-input
            id="townName"
            v-model="newTown.townName"
            type="text"
            placeholder="İlçe Adı"
            :state="newTown.townName.length > 0"
          />
          <b-form-invalid-feedback>
            İlçe adı boş bırakılamaz.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import * as apiSehir from '@/api/tanimlar/sehir'
import * as apiTown from '@/api/tanimlar/town'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import vSelect from 'vue-select'
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BButtonGroup, BButton, BModal, BForm, VBModal, BCol, BRow, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'IlceList',
  components: {
    vSelect,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButtonGroup,
    BButton,
    BModal,
    BForm,
    BCol,
    BRow,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      selectedCountry: { countryId: 1, countryName: 'Türkiye' },
      selectedCity: { cityId: -1, cityName: 'Seçiniz' },
      newCountry: { },
      newTown: { townId: 0, townName: '' },
      sehirList: [],
      pageLength: 15,
      columns: [
        {
          label: 'İlçe Adı',
          field: 'townName',
        },
        {
          label: 'İşlem',
          field: 'action',
          sortable: false,
          width: '15%',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    ...mapGetters({
      ulkeList: 'ulke/GET_COUNTRYLIST',
      ulke: 'ulke/GET_COUNTRY',
    }),
  },
  watch: {
    selectedCountry() {
      this.getCityList()
      this.selectedCity = { cityId: -1, cityName: 'Seçiniz' }
    },
    selectedCity() {
      this.getTownList()
    },
  },
  created() {
    this.setCountryList([])
  },
  mounted() {
    this.getCountryList()
    this.getCityList()
  },
  methods: {
    ...mapMutations({
      setCountryList: 'ulke/SET_COUNTRYLIST',
    }),
    ...mapActions({
      fetchCountryList: 'ulke/action_get_countries',
    }),
    getCountryList() {
      this.fetchCountryList()
    },
    getCityList() {
      apiSehir.getCityByCountryId(this.selectedCountry.countryId).then(res => {
        this.sehirList = res.data.list
      })
    },
    getTownList() {
      apiTown.GetTownsByCityId(this.selectedCity.cityId).then(res => {
        this.rows = res.data.list
      })
    },
    onDataGridItemClicked(params) {
      return params
    },
    clearModal() {
      this.newTown.townId = 0
      this.newTown.townName = ''
    },
    onModalSaveTown() {
      if (this.newTown.townName.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Kayıt Eklenemedi',
            text: 'Boş kayıt eklenemez.',
            icon: 'PlusSquareIcon',
            variant: 'danger',
          },
        })
        return
      }
      const townItem = {
        townId: 0, cityId: this.selectedCity.cityId, townName: this.newTown.townName,
      }
      apiTown.AddTown(townItem).then(() => {
        this.getTownList()
      })
      this.clearModal()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'İlçe ekle',
          text: 'Yeni kayıt eklendi.',
          icon: 'PlusSquareIcon',
          variant: 'success',
        },
      })
      this.getTownList()
    },
    onModalCancelled() {
      this.clearModal()
    },
    onUpdateTownBtnClicked(row) {
      this.newCountry = this.selectedCountry
      this.newCity = this.selectedCity
      this.newTown.townId = row.townId
      this.newTown.townName = row.townName
      this.$bvModal.show('ilceguncelle-modal')
    },
    onDeleteTownBtnClicked(row) {
      this.$bvModal.msgBoxConfirm(` '${row.townName}' tanımı silinecek, onaylıyor musunuz?`, {
        title: 'Uyarı!',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Evet',
        cancelTitle: 'Hayır',
        footerClass: 'p-2',
        hideHeaderClose: true,
        noCloseOnBackdrop: true,
        centered: true,
      })
        .then(value => {
          if (value) {
            apiTown.DeleteTown(row.townId).then(() => {
              this.getTownList()
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Kayıt Silindi',
                text: `${row.townName} kaydı silindi.`,
                icon: 'DeleteIcon',
                variant: 'danger',
              },
            })
            // this.getTownList()
          }
        })
    },
    onModalUpdateTown() {
      if (this.newTown.townName.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Kayıt Güncellenemedi',
            text: 'Boş kayıt güncellenemez.',
            icon: 'PlusSquareIcon',
            variant: 'danger',
          },
        })
        return
      }
      const townItem = {
        townId: this.newTown.townId, cityId: this.selectedCity.cityId, townName: this.newTown.townName,
      }
      apiTown.UpdateTown(townItem).then(() => {
        this.getTownList()
      })
      this.clearModal()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Kayıt Güncellendi',
          text: 'Kayıt başarıyla güncellendi',
          icon: 'EditIcon',
          variant: 'warning',
        },
      })
      this.getTownList()
    },
  },
}
</script>
<style lang='scss'>
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
